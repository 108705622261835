<template>
  <action-menu :teleport-menu="teleport" check-for-overflow>
    <template #trigger>
      <button class="flex items-center p-0-5 pr-1 rounded-md">
        <icon-base
          height="20"
          width="20"
          viewBox="0 0 20 14"
          :icon="IconShowPassNoBg"
          class="ml-1 text-gray-500"
        />
        <span>{{ seenByCount }}</span>
      </button>
    </template>
    <div class="bg-white w-64 py-4 pl-3 pr-5 overflow-y-hidden">
      <lf-h3>{{ $t("DEALS.DEAL_NOTES.VIEWS") }}</lf-h3>
      <div class="mt-2">
        {{ $t("DEALS.DEAL_NOTES.USERS_WHO_SAW_NOTE") }}
      </div>
      <div class="mt-4 max-h-50 overflow-y-auto">
        <div
          v-for="record in seenBy"
          :key="record.id"
          class="mb-3 flex items-center space-x-2"
        >
          <lf-avatar :username="formatUserFullName(record.user)" :size="40" />
          <div>
            <div class="font-semibold text-gray-600">
              {{ record.user.first_name }} {{ record.user.last_name }}
            </div>
            <div>{{ record.user.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </action-menu>
</template>
<script setup lang="ts">
import { computed } from "vue";
import type { ReadNote } from "@/models/notes";
import { formatUserFullName } from "@/helpers/formatting";
import IconShowPassNoBg from "@/components/icons/IconShowPassNoBg.vue";
import IconBase from "@/components/ui/IconBase.vue";
import LfAvatar from "@/components/ui/LFAvatar.vue";

import ActionMenu from "@/components/ui/ActionMenuV2.vue";

const { seenBy, teleport = true } = defineProps<{
  seenBy: ReadNote[];
  teleport?: boolean;
}>();

const seenByCount = computed(() => seenBy?.length);
</script>
